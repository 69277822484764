import React from "react"
import "./reset_link_send.screen.scss";
import Main from "common_components/hoc/main.hoc";
import { FontFamily, PrimaryButton } from "utils/imports.utils";
import { ButtonSize, ButtonTypes } from "utils/interface.utils";



const ResetLinkSend = (props:any) => {
    return (
        <Main className="admin">
            <div className="reset">
                <div className="reset_container">
                    <div className="reset_header">
                        <div className="reset_heading">Reset link</div>
                        <div className="reset_description">Password reset link send to your email</div>
                    </div>
                    <div className="reset_button_container">
                        <PrimaryButton
                            fontFamily={FontFamily.semiBold}
                            text="Back to login"
                            type={ButtonTypes.PRIMARY}
                            size={ButtonSize.SMALL}
                        />
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default ResetLinkSend;