import axios from "axios";
import { formatDate } from "date-fns";
import {instance} from "utils/axios.utils";
import { Functions } from "utils/imports.utils";
import { setUser } from "utils/redux.utils";
const auth = {
  login: (data) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "auth/user_login";
      instance().post(url, data).then(res=>{
        resolve(res.data)
        localStorage.setItem("token",res.data.token)
        localStorage.setItem("user",JSON.stringify(res.data.data))
        setUser(res.data.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  getAllVendors: (body) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/get_all_vendors";
      instance().post(url,body).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  getAllUsersService: (body) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/get_all_users";
      instance().post(url,body).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  getAllSupportRequest: (body) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/support";
      instance().post(url,body).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },
  updateSupportStatus: (data) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/support/status";
      instance().put(url, data).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  totalCountVendors: () => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/total_count_vendors";
      instance().get(url).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  totalCountServices: () => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/total_count_services";
      instance().get(url).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },
  totalCountSupport: (body) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/total_count_support";
      instance().post(url,body).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },
  totalCountAdvertisement: () => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/total_count_advertisement";
      instance().get(url).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  changePassword: (data) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "auth/change_password";
      instance().post(url, data).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  passwordRecovery: (data) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "auth/forget_password";
      instance().post(url, data).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  resetPassword: (data) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "auth/reset_password";
      instance().post(url, data).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  getUser: () => {
    let promise = new Promise((resolve, reject)=>{
      let url = "auth/get_user";
      instance().post(url).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  editUser: (body) => {
    let promise = new Promise((resolve, reject)=>{
      let token:any = localStorage.getItem("token")
     
      let baseURL = Functions.getBaseURL()+"/api/v1/"
      let url = "auth/edit_user";
      const headers = {
        'Content-Type': `multipart/form-data`, // Add boundary for multipart
        'Authorization': token, // Replace with your actual token
      };
      axios.post(`${baseURL}${url}`,body, { headers })
  .then(response => {
    console.log('Success:', response.data);
  })
  .catch(error => {
    console.error('Error:', error);
  });
      // instance().post(url,body).then(res=>{
      //   resolve(res.data)
      // }).catch(error=>{
      //   if(error.response){
      //     reject(error.response.data.message)
      //   }else{
      //     reject(error)
      //   }
      // })
    })
    return promise
  },

  deleteVendor: (body) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/delete_vendor";
      instance().post(url,body).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },
  uploadFile: (data) => {
    let promise = new Promise((resolve, reject) => {
      let url = 'auth/get_signed_url';
      instance()
        .post(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else {
            reject(error);
          }
        });
    });
    return promise;
  },

  rangeVendor: () => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/range_vendors";
      instance().post(url).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  rangeServices: () => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/range_service";
      instance().post(url).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  rangeAdvertisement: () => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/range_ads";
      instance().post(url).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },

  // deleteVendorByAdmin: (body) => {
  //   let promise = new Promise((resolve, reject)=>{
  //     let url = "admin/delete_vendor";
  //     instance().post(url,body).then(res=>{
  //       resolve(res.data)
  //     }).catch(error=>{
  //       if(error.response){
  //         reject(error.response.data.message)
  //       }else{
  //         reject(error)
  //       }
  //     })
  //   })
  //   return promise
  // },
  datePickerVendors: (body) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "admin/date_picker_vendors";
      instance().post(url,body).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error.response.data.message)
        }else{
          reject(error)
        }
      })
    })
    return promise
  },
};


export default auth;