import React, { useEffect, useRef, useImperativeHandle, InputHTMLAttributes } from "react";
import { useSelector } from "react-redux";
import { useSetState } from "utils/functions.utils";
import "./input.ui.scss";
import { InputTypes } from "utils/interface.utils";

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: any;
  iconPosition?: any;
  iconOnPress?: any;
  error?: any;
  label?: string;
  variant?: string;
  containerStyle?: string;
  inputStyle?: string;
  refElement?: any;
  placeholder?: string;
  isTextArea?: boolean
  onChange?:any
}

const Input = (props: IInputProps) => {
  // Redux

  // State
  const [state, setState] = useSetState({ focus: false });

  //Hooks
  useEffect(() => {
  }, []);

  //Logic

  const getInputStyle = () => {
    if (props.variant === InputTypes.LINE) {
      return "input_container input_variant_line";
    } else {
      return "input_container";
    }
  };

  const getFocusStyle = () => {
    if (props.variant === InputTypes.LINE) {
      return "focus_variant_line";
    } else {
      return "focus";
    }
  };

  return (
    <div className={`input_wrapper ${props.containerStyle || ""}`}>
      {props.label && <div className="input_label_wrapper">{props.label}</div>}
      <div className={state.focus ? `${getFocusStyle()} ${getInputStyle()}` : `${getInputStyle()}`}>
        {props.icon && props.iconPosition === "start" && (
          <div
            className="icon_wrapper"
          >
            {props.icon && <img src={props.icon} alt="image" className={"input_icon"} />}
          </div>
        )}
        {props.isTextArea ?
          <textarea
            id={state.focus ? "set_bg" : "input_field"}
            ref={props.refElement}
            placeholder={props.placeholder}
            onFocus={() => setState({ focus: true })}
            onBlur={() => {
              setState({ focus: false });
            }}
            onChange={(e) => props.onChange(e)}
          /> :
          <input
            id={state.focus ? "set_bg" : "input_field"}
            onFocus={() => setState({ focus: true })}
            onBlur={() => {
              setState({ focus: false });
            }}
            ref={props.refElement}
            {...props}
          />
        }
        {props.icon && props.iconPosition === "end" && (
          <div
            className="icon_wrapper"
            onClick={() => {
              props.iconOnPress();
            }}
          >
            {props.icon && <img src={props.icon} alt="image" className={"input_icon"} />}
          </div>
        )}
      </div>
      {props.error && props.error.length > 0 && <div className="input_field_error">{props.error}</div>}
    </div>
  );
};

export default Input;
