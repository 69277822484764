import Assets from "imports/assets.import";
import "./confirmation_modal.component.scss";
import { Colors, FontFamily, PrimaryButton } from "utils/imports.utils";
import { ButtonSize, ButtonTypes } from "utils/interface.utils";

interface IConfirmationModal {
    primaryText:string,
    secondaryText?:string,
    acceptHandle:Function,
    acceptBtnText:string,
    rejectHandle:Function,
    rejectBtnText:string,
    closeModal:Function,
}


const ConfirmationModal = (props:IConfirmationModal) => {
    
    const handleModalClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if ((e.target as HTMLDivElement).className.includes("confirmation_modal")) {
            props.closeModal();
        }
    }

    return (
        <div className="confirmation_modal" onClick={handleModalClose}>
            <div className="confirmation_modal_container">
                <div className="confirmation_modal_header">
                    <div className="confirmation_modal_primary_text">
                        {props.primaryText}
                    </div>
                    <div onClick={() => props.closeModal()} className="confirmation_modal_close_container">
                        <img className="confirmation_modal_close_img" src={Assets.close_black} alt="Close Modal Icon"></img>
                    </div>
                </div>
                <div>
                    <div className="confirmation_modal_secondary_text">{props.secondaryText}</div>
                </div>
                <div className="confirmation_modal_button_container">
                    <div className="confirmation_modal_button_reject">
                        <PrimaryButton
                            type={ButtonTypes.OUTLINED}
                            size={ButtonSize.SMALL}
                            text={props.rejectBtnText}
                            onClick={props.rejectHandle}
                            fontFamily={FontFamily.medium}
                            color={Colors.fontSecondary}
                            style={{borderColor:"#9CA3AF"}}
                        />
                    </div>
                    <div className="confirmation_modal_button_accept">
                        <PrimaryButton
                            onClick={props.acceptHandle}
                            backgroundColor={Colors.error}
                            fontFamily={FontFamily.medium}
                            size={ButtonSize.SMALL}
                            text={props.acceptBtnText}
                        />
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ConfirmationModal;