import { testDispatch } from '../utils/redux.utils';
import {instance} from "../utils/axios.utils"

const supportRequest = {
  createSupportRequest: (body: any) => {
    let promise = new Promise((resolve, reject)=>{
      let url = "auth/support"
      instance().post(url, body).then(res=>{
        resolve(res.data)
      }).catch(error=>{
        if(error.response){
          reject(error)
        }else{
          reject(error)
        }
      })
    })
    return promise
  }
}

export default supportRequest

