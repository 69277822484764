import test from 'models/test.model';
import auth from 'models/auth.model';
import serviceRequest from 'models/service_request';
import supportRequest from 'models/suppor_request';

export const Models = {
  test,
  auth,
  serviceRequest,
  supportRequest
}

export default Models