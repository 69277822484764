import React from "react";
import Main from "common_components/hoc/main.hoc";
import "./password_recovery.screen.scss";
import { useSetState } from "utils/functions.utils";
import { Assets, FontFamily, Input, Models, PrimaryButton } from "utils/imports.utils";
import { ButtonSize, ButtonTypes } from "utils/interface.utils";
import { useNavigate } from "react-router-dom";

const PasswordRecovery = (props:any) => {
    const navigate = useNavigate();
    const [state,setState] = useSetState({
        email:"",
        emailError:"",
        activity:false,
    })

    const handleSend = async(e:any) => {
        setState({ activity: true });

        try {
            const response = await Models.auth.passwordRecovery({ email: state.email });
            if (response) {
                console.log("Password recovery email sent:", response);
                navigate("/admin/reset_link_send");
            }
        } catch (error) {
            console.error("Password recovery error:", error);
            setState({ emailError: "Password recovery failed. Please try again." });
        } finally {
            setState({ activity: false });
        }
    }

    return(
        <Main className="admin">
            <div className="recovery">
                <div className="recovery_container">
                    <div className="recovery_details_container">
                        <div className="recovery_heading">
                        Enter your email address and we’ll send you link to reset your password
                        </div>
                        <div className="recovery_input_container_email">
                            <Input
                                onChange={(e) => setState({email:e.target.value})}
                                value={state.email}
                                type="email"
                                placeholder="enter your email id here"
                                error={state.emailError}
                            />
                        </div>
                    </div>
                    <div className="recovery_button_container">
                         <div className="recovery_button_container_send">
                            <PrimaryButton
                                onClick={(e) => handleSend(e)}
                                fontFamily={FontFamily.semiBold}
                                text="Send"
                                type={ButtonTypes.PRIMARY}
                                size={ButtonSize.SMALL}
                            />
                         </div>
                         <div className="recovery_button_container_back">
                            <PrimaryButton
                                left_icon={Assets.caret_left}
                                onClick={(e) => navigate("/admin/login")}
                                text="back"
                                type={ButtonTypes.TEXT}
                                fontFamily={FontFamily.semiBold}
                            />
                         </div>
                    </div>
                </div>
            </div>

        </Main>
    )
}

export default PasswordRecovery;