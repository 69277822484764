import React from "react";
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import "./slider.component.scss";

interface ISlider {
    step?: number | any;
    value?: [number,number];
    min?: any;
    max?: any;
    disabled?:boolean;
    defaultValue?: [number,number];
    onInput?:any;
}

const Slider = (props : ISlider) => {
    return (
        <div className="slider_container">
            <RangeSlider
                value={props.value}
                step={props.step}
                min={props.min}
                max={props.max}
                disabled={props.disabled}
                defaultValue={props.defaultValue}
                onInput={props.onInput}
                {...props}
            />
        </div>
    )
}

export default Slider;