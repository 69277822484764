import React from "react";
import { DateRangePicker } from 'react-date-range';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css"; 
import "./date_picker_range.component.scss"
import { useSetState } from "utils/functions.utils";
import { PrimaryButton } from "utils/imports.utils";
import { ButtonSize, ButtonTypes } from "utils/interface.utils";
import { Colors, FontFamily } from "utils/theme.utils";

interface IDatePickerRange {
    handleSave:any,
    months?:number,
    direction?:string,
}

const DatePickerRange = (props:IDatePickerRange) => {
      const [state,setState] = useSetState({
            selectedDateRange:{
                startDate:new Date(),
                endDate:new Date(),
                key:"selection",
            }
      })
    
      const handleSelect = (ranges) => {
        setState({
            selectedDateRange:ranges.selection
        })
      };

      const handleClear = () => {
        setState({
            selectedDateRange:{
                startDate:new Date(),
                endDate:new Date(),
                key:"selection"
            }
        })
      }

    return (
        <React.Fragment>
            <div className="date_picker_range_container">
                <DateRangePicker
                    onChange={handleSelect}
                    ranges={[state.selectedDateRange]}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={props.months}
                    direction={props.direction}
                />
                <div className="date_picker_range_buttons_container">
                    <div className="date_picker_range_button_container">
                        <PrimaryButton
                            onClick={() => props.handleSave(state.selectedDateRange)}
                            color={Colors.primaryColor}
                            type={ButtonTypes.TEXT}
                            text="Save"
                            fontFamily={FontFamily.semiBold}
                            size={ButtonSize.SMALL}
                        />
                    </div>
                    <div className="date_picker_range_button_container">
                        <PrimaryButton
                            onClick={handleClear}
                            color={"#3d91ff"}
                            type={ButtonTypes.TEXT}
                            text="Clear"
                            fontFamily={FontFamily.semiBold}
                            size={ButtonSize.SMALL}
                        />
                    </div>
                </div>
            </div>
      </React.Fragment>
    )
}

export default DatePickerRange;