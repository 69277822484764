import Assets from "imports/assets.import";
import "./vendor.screen.scss";
import { FontFamily, PrimaryButton } from "utils/imports.utils";
import { ButtonSize, ButtonTypes } from "utils/interface.utils";
import Main from "common_components/hoc/main.hoc";

export default function Vendor(){
    return (
        <Main>
            <div className="vendor">
                <div className="vendor_container">
                    <div className="vendor_header">
                        <div className="vendor_img_container">
                            <img className="vendor_img" src={Assets.vendor_illustration} alt="Vendor Illustration" />
                        </div>
                        <div className="vendor_heading">Join Quick Serve today and
                        elevate your business!</div>
                        <div className="vendor_desc">Are you a skilled technician, plumber, electrician, doctor, or service provider looking to expand your customer base? Quick Serve is the perfect platform for you! Connect with local customers who need your expertise and watch your business thrive.</div>
                        <div className="vendor_desc_sec">
                            Click the button below to download the APK file,Install the app and get started.
                        </div>
                        <div className="vendor_button_container">
                            <PrimaryButton left_icon={Assets.download} text={"Download APK"} type={ButtonTypes.PRIMARY}size={ButtonSize.LARGE} fontFamily={FontFamily.regular}/>
                        </div>
                    </div>
                    <div className="vendor_how_to_container">
                        <div className="vendor_how_to_img_container">
                            <img src={Assets.how_to_install_illustration} alt="How to install illustration" />
                        </div>
                        <div className="vendor_how_to_heading">How to Install APK File:</div>
                        <div className="vendor_how_to_steps_container">
                            <div className="vendor_how_to_steps"><span>Step 1 :</span> Tap the <span>"Download APK"</span> button above.</div>
                            <div className="vendor_how_to_steps"><span>Step 2 :</span> Open the downloaded file from your device's notification bar or file manager.</div>
                            <div className="vendor_how_to_steps"><span>Step 3 :</span> If prompted, enable <span>"Install unknown apps"</span> in your device settings.</div>
                            <div className="vendor_how_to_steps"><span>Step 4 :</span> Tap <span>"Install"</span> on the confirmation screen.</div>
                            <div className="vendor_how_to_steps"><span>Step 5 :</span> After installation, tap <span>"Open"</span> to launch the app.</div>
                        </div>

                    </div>
                </div>
            </div>
        </Main>
    )
}