import Assets from "imports/assets.import";
import "./request_history_modal.component.scss";

interface IRequestHistory {
    requestId:number | string,
    userMobile:number | string,
    userLocation:string,
    price:number | string,
    userMailId:string,
    closeModal:Function,
    vendorDetails:Array<object>
}

const RequestHistoryModal = (props:IRequestHistory) => {
    const handleModalClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if ((e.target as HTMLDivElement).className.includes("request_history")) {
            props.closeModal();
        }
    }

    return (
        <div className="request_history" onClick={handleModalClose}>
            <div className="request_history_container">
                <div className="request_history_header">
                    <div className="request_history_header_title">
                    Request history
                    </div>
                    <div className="request_history_header_close_container" onClick={() => props.closeModal()}>
                        <img src={Assets.close_black} alt="Request History Modal Close"/>
                    </div>
                </div>
                <div className="request_history_user_details_container">
                    <div className="request_history_user_details_data">
                        <div className="request_history_user_details_data_label">Request id</div>
                        <div className="request_history_user_details_data_value">{props.requestId}</div>
                    </div>
                    <div className="request_history_user_details_data">
                        <div className="request_history_user_details_data_label">Mobile</div>
                        <div className="request_history_user_details_data_value">{props.userMobile}</div>
                    </div>
                    <div className="request_history_user_details_data">
                        <div className="request_history_user_details_data_label">Location</div>
                        <div className="request_history_user_details_data_value">{props.userLocation}</div>
                    </div>
                    <div className="request_history_user_details_data">
                        <div className="request_history_user_details_data_label">Price range</div>
                        <div className="request_history_user_details_data_value">{props.price}</div>
                    </div>
                    <div className="request_history_user_details_data">
                        <div className="request_history_user_details_data_label">Mail id</div>
                        <div className="request_history_user_details_data_value">{props.userMailId}</div>
                    </div>
                </div>
                <div className="request_history_vendor_details">
                    <div className="request_history_vendor_details_title">Vendor details sent</div>
                    <div className="request_history_vendor_details_table">
                        <div className="request_history_vendor_details_table_header">
                            <div className="request_history_vendor_details_table_header_item">Vendor id</div>
                            <div className="request_history_vendor_details_table_header_item">Name</div>
                            <div className="request_history_vendor_details_table_header_item">Mail id</div>
                            <div className="request_history_vendor_details_table_header_item">Mobile no</div>
                        </div>
                        <div className="request_history_vendor_details_table_body">
                            {props.vendorDetails.map((data:any,index) => (
                                    <div className="request_history_vendor_details_table_body_item">
                                        <div className="request_history_vendor_details_table_body_item_data">{data._id}</div>
                                        <div className="request_history_vendor_details_table_body_item_data">{data.name}</div>
                                        <div className="request_history_vendor_details_table_body_item_data">{data.email}</div>
                                        <div className="request_history_vendor_details_table_body_item_data">{data.phone_number}</div>
                                    </div>
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default RequestHistoryModal;