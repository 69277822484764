export const Colors = {
  primaryColor: "#FF5C60",
  secondaryColor:"#111827",
  primaryWhite:"#F8FAFC",
  fontPrimary: "#111827",
  fontSecondary:"#9CA3AF",
  placeholder: "#D1D5DB",
  buttonBackgroundInActive:"#F1F1F1",
  sliderBackground:"#E2E8F0",
  buttonBorder: "#D1D5DB",
  black: "#000000",
  white: "#FFFFFF",
  gray: "#374151",
  transparent: "transparent",
  error: "#DC2626",
  lightGray: "#E5E7EB",
  opacityGray: "rgba(0, 0, 0, 0.3)",
};

export const FontFamily = {
  regular:"PlusJakartaSans-Regular",
  medium:"PlusJakartaSans-Medium",
  semiBold:"PlusJakartaSans-SemiBold",
  bold:"PlusJakartaSans-Bold",
}