import { GoogleMap, Autocomplete, MarkerF } from "@react-google-maps/api";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import Modal from "react-responsive-modal";
import { getAddressFromCoords, getCurrentLocationCoords, useSetState } from "utils/functions.utils";
import "./location_modal.component.scss"
import 'react-responsive-modal/styles.css';
import React from "react";
import { FontFamily, Input, PrimaryButton } from "utils/imports.utils";
// import { Center } from "@react-three/drei";
import { ButtonSize, ButtonTypes } from "utils/interface.utils";

const LocationModal = forwardRef((props:any,ref) => {
    const [state,setState] = useSetState({
        isModalOpen:false,
        center:{lat:0,lng:0},
        map:null,
        autocomplete:null,
        addressFull:"",
        addressShort:"",
        location:{lat:null,lng:null}
    })

    const handleMapOnLoad = React.useCallback(function callback(map: any) {
        // const bounds = new window.google.maps.LatLngBounds(state.center);
        // map.fitBounds(bounds);
        setState({ map:map });
    }, []);

    const handleMapBoundsChanged = () => {
        const mapCenter = state.map?.getCenter();
        const location = {
            lat: mapCenter?.lat(),
            lng: mapCenter?.lng(),
        };
        setState({ location });
    }

    const getAddressDetails = async (coords:any) => {
        try{
            const response:any = await getAddressFromCoords(coords.lat,coords.lng);
            setState({
                addressFull:response.addressFull,
                addressShort:response.addressShort,
            })
        }catch(error){
            console.log("Error from getAddressDetails:",error)
        }
    } 

    const handleMapOnDragEnd = async () => {
        if (state.map) {
            const mapCenter = state.map.getCenter();
            const location = {
                lat: mapCenter.lat(),
                lng: mapCenter.lng(),
            };
            setState({ location });
 
            getAddressDetails(location)
        }
    }

    const handleMarkerDragEnd = async (event) => {
        const coords = {lat:event.latLng.lat(),lng:event.latLng.lng()}
        setState({
            center:{lat:coords.lat,lng:coords.lng},
            location:{lat:coords.lat,lng:coords.lng}
        })
        getAddressDetails(coords)
    }

    const handleOnLoadAutocomplete = (autocomplete:any) => {
        setState({autocomplete:autocomplete})
    }

    const handleOnPlaceChangedAutocomplete = async () => {
        if(state.autocomplete !== null){
            const autocomplete = state.autocomplete;
            const coords = {
                lat:autocomplete.getPlace().geometry.location.lat(),
                lng:autocomplete.getPlace().geometry.location.lng(),
            }
            setState({
                location:coords,
                center:coords,
            })

            getAddressDetails(coords);
        }
    }

    const handleOnUnmount =  React.useCallback(function callback(map) {
        setState({
            map:null,
        })
      }, [])



    const handleSaveLocation = () => {
        setState({isModalOpen:false})
        props.handleCallBack(state.location,state.addressShort)
    }

    const openModalAndFetchLocation = async () => {
        try {
            const coords = await getCurrentLocationCoords();
            const currentLocation = { lat: coords.latitude, lng: coords.longitude };
            setState({
                isModalOpen: true, // Open the modal here
                center: currentLocation,
                location: currentLocation,
            });
            getAddressDetails(currentLocation);
        } catch (error) {
            console.log("Error:", error);
        }
    };

    useImperativeHandle(ref,() => ({
        openModal(){
            openModalAndFetchLocation();
        },
        closeModal() {
            setState({ isModalOpen: false });
        },
    }))

    return (
        <div>
            <Modal 
                classNames={{overlay:"location_modal_overlay",modal:"location_modal_container"}} 
                open={state.isModalOpen} 
                onClose={() => setState({isModalOpen:false})} 
                center
            >
                <div className="location_modal_title">Pick your location</div>
                <GoogleMap
                        mapContainerStyle={{
                            width:"100%",
                            height:"480px",
                            borderRadius:"10px"
                        }}
                        center={state.center}
                        zoom={14}
                        onBoundsChanged={handleMapBoundsChanged}
                        onLoad={handleMapOnLoad}
                        onDragEnd={handleMapOnDragEnd}
                        onUnmount={handleOnUnmount}
                        options={{
                            zoomControl:false,
                            fullscreenControl:false,
                            mapTypeControl:false,
                            streetViewControl: false,
                        }}
                >
                    <Autocomplete
                        onLoad={handleOnLoadAutocomplete}
                        onPlaceChanged={handleOnPlaceChangedAutocomplete}
                    >
                        <div className="location_modal_autocomplete_container">
                            <Input
                                type="text"
                                placeholder="Search your places..."
                            />
                        </div>
                    </Autocomplete>
                    {state.location.lat && state.location.lng && 
                        <MarkerF
                            animation={google.maps.Animation.DROP}
                            position={state.location}
                            onDragEnd={handleMarkerDragEnd}
                            draggable
                        />
                    }
                </GoogleMap>
                {
                    state.addressFull && state.addressShort && (
                        <div className="location_modal_address_container">
                            <div>
                                <div className="location_modal_address_title">Full Address:</div>
                                <div className="location_modal_address_desc">{state.addressFull}</div>
                            </div>
                            <div>
                                <div className="location_modal_address_title">Short Address</div>
                                <div className="location_modal_address_desc">{state.addressShort}</div>
                            </div>
                        </div>
                    )
                }
                <div className="location_modal_save_button_container">
                    <PrimaryButton
                        text="Save location"
                        type={ButtonTypes.PRIMARY}
                        size={ButtonSize.SMALL}
                        fontFamily={FontFamily.semiBold}
                        onClick={() => handleSaveLocation()}
                    />
                </div>
            </Modal>
        </div>
    )
})

export default LocationModal;