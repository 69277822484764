import Main from "common_components/hoc/main.hoc"
import "./password_changed.screen.scss";
import { Constants, FontFamily, PrimaryButton } from "utils/imports.utils";
import { ButtonSize, ButtonTypes } from "utils/interface.utils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PasswordChanged = (props:any) => {
    const navigate = useNavigate();
    useEffect(() => {
    },[])

    return (
        <Main className="admin">
            <div className="password_changed">
                <div className="password_changed_container">
                    <div className="password_changed_header">
                        <div className="password_changed_heading">
                        Password changed
                        </div>
                        <div className="password_changed_description">
                        Your password has now changed successfully.
                        </div>
                    </div>
                    <div className="password_changed_button_container">
                        <PrimaryButton
                            fontFamily={FontFamily.semiBold}
                            onClick={(e) => {navigate("/admin/login",{replace:true})}}
                            size={ButtonSize.SMALL}
                            text="Back to login"
                            type={ButtonTypes.PRIMARY}
                        />
                    </div>
                </div>
            </div>
        </Main>
    )
}

export default PasswordChanged;