  export interface storeAction {
    type: string;
    payload: object | object[];
  }
  export interface IDropdownData {
    label: string;
    value: string;
  }
  export interface IObject {
    [key: string]: any;
  }
  export interface IModal {
    openModal: Function;
    closeModal: Function;
  }
  
  export interface IFileUpload {
    openUpload: Function;
  }
  
  export interface IReduxStore {
    test: Object;
    user: IObject;
    app: IObject;
    guidedFlow: IGuidedFlowState;
    answer: IAnswerState;
    question: IQuestionState;
    org: IOrgState;
    template: ITemplateState;
    historyLog: IObject;
    projectData: {
      questions: Array<IObject>;
      points: Array<IPoints>;
      measurements: Array<IMeasurements>;
      template: ITemplate;
      guided_flows: Array<IObject>;
      work_flow: string;
      history_logs: Array<IObject>;
      point_names: Array<string>;
      comments: Array<IComments>;
    };
    temporaryData: {
      zoomValue: number;
      loadModel?: boolean;
      editMeasurement: IMeasurements;
      replaceMeasurements: Array<IMeasurements>;
      progressData: IObject;
      activeQuestion: IObject;
      options: IObject;
      selectedQuestion: IObject;
      selectedPoints: Array<IPoints>
      guidedFlowTrueDistance: IObject;
      measuring: BOOLEAN;
      temporarySelectedPoints: Array<IPoints>,
      temporarySelectedQuestion: IObject,
      addComment: boolean,
      gridAnalysis: IGridAnalysis,
      removableCommentId: String;
      guidedFlowTrueSlope: IObject;
      showPointsTable: boolean,
      showMeasurementsTable: boolean,
      removableComments: Array<string>,
      exposureValue: number,
      ambientIntensity: number,
      ambientColor: string,
      directIntensity: number,
      directColor: string,
      screenRecord: string,
      screenshot: boolean,
      hideBlackScreen: boolean,
      scroll: number,
      guidedFlowElevationAnalysis: IObject;
      elevationAnalysis: IElevationAnalysis;
      singleReferencePoint: boolean;
      totalPagesCount: number;
      showComments: boolean;
      commentsData: IComments;
      viewComment: string
    };
    media: IObject;
    editor: IEditorState;
  }
  
  export interface IResponseList {
    status: string;
    success: string;
    data: IObject;
  }
  
  export interface IResponseData {
    status?: string;
    success?: string;
    message?: string;
    data: IObject;
  }
  
  export enum BOOLEAN {
    TRUE = "true",
    FALSE = "false",
  }
  
  export enum AnswerTypes {
    NUMBER = "NUMBER",
    RADIO = "RADIO",
    CHECKBOX = "CHECKBOX",
    VALUE = "VALUE",
    TEXT = "TEXT",
    BOOLEAN = "BOOLEAN",
    EXPORT = "EXPORT",
    IMAGE = "IMAGE",
  }
  export enum DataTypes {
    QUESTION = "QUESTION",
    REFERENCE = "REFERENCE",
    LOGIC = "LOGIC",  
    VALUE = "VALUE",
    INSPECTION = "INSPECTION",
  }
  export enum LogicTypes {
    NUMBER = "NUMBER",
    VALUE = "VALUE",
    TEXT = "TEXT",
    NUMBER_GREATER = "NUMBER_GREATER",
    NUMBER_GREATER_EQ = "NUMBER_GREATER_EQ",
    NUMBER_LESS = "NUMBER_LESS",
    NUMBER_LESS_EQ = "NUMBER_LESS_EQ",
    NUMBER_EQ = "NUMBER_EQ",
    NUMBER_NE = "NUMBER_NE",
    NOT_BLANK = "NOT_BLANK",
    ADDITION = "ADDITION",
    SUBTRACTION = "SUBTRACTION",
    MULTIPLY = "MULTIPLY",
    DIVISION = "DIVISION",
    PERCENTAGE = "PERCENTAGE",
    MINIMUM = "MINIMUM",
    MAXIMUM = "MAXIMUM",
    AVERAGE = "AVERAGE",
    OTHER = "OTHER",
    // { label: "Date is", value: "DATE_IS" },
    // { label: "Date is after", value: "DATE_AFTER" },
    // { label: "Date is before", value: "DATE_BEFORE" },
    // { label: "Is valid date", value: "DATE_IS_VALID" },
  }
  
  export enum ButtonTypes {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY",
    OUTLINED = "OUTLINED",
    TEXT = "TEXT",
    DISABLED = "DISABLED"
  }
  
  export enum ButtonSize {
    SMALL = "Small",
    MEDIUM = "Medium",
    LARGE = "Large",
  }
  
  export enum InputTypes {
    LINE = "LINE",
  }
  
  export interface IMenuItem {
    title: string;
    onPress: Function;
    className?: string;
    type?: string;
  }
  
  export interface IGuidedFlowState {
    pdfUrl: string;
    canvasArray: string[];
    template?: IObject;
    pageAttributes?: IObject;
    guided_flows: Array<IObject>;
  }
  
  export interface IAnswerState {
    answers: Array<IAnswers>;
    selectedReferencePoint: IObject;
  }
  
  export interface IQuestionState {
    question_data: Array<IObject>;
    question: IObject;
    question_list: Array<IObject>;
  }
  
  export interface ILogicBlock {
    logic_answer: any;
    primary_data_type: string;
    primary_data: string;
    primary_data_value: string;
    secondary_data_type?: string;
    secondary_data_value?: string;
    secondary_data?: string;
    selected_logic?: string;
    primary_question?: any;
    secondary_question?: any;
    primary_axis?: string;
    secondary_axis?: string;
    primary_question_type?: string;
    secondary_question_type?: string;
  }
  
  export interface IAnswers {
    answer: string;
    answer_type: string;
    size: string;
    key: string;
    logics: Array<ILogicBlock>;
    reference_name: string;
    offsetX: number;
    offsetY: number;
    page: number;
    image?: any;
    _id?: string;
  }
  
  export enum QuestionTypes {
    MEASUREMENT = "MEASUREMENT",
    MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
    TEXT = "TEXT",
    DROPDOWN = "DROPDOWN",
    TIME = "TIME",
    DATE = "DATE",
    YES_OR_NO = "YES_OR_NO",
    TRUE_OR_FALSE = "TRUE_OR_FALSE",
    IMAGE_UPLOAD = "IMAGE_UPLOAD",
  }
  
  export enum MeasurementTypes {
    SLOPE = "SLOPE",
    TRUE_SLOPE = "TRUE_SLOPE",
    DISTANCE = "DISTANCE",
    TRUE_DISTANCE = "TRUE_DISTANCE",
    ELEVATION = "ELEVATION",
    ELEVATION_ANALYSIS = "ELEVATION_ANALYSIS",
    OFFSET = "OFFSET",
  }
  
  export interface IQuestionBody {
    title?: string;
    description?: string;
    answer_type?: string;
    measurement_type?: string;
    template?: string;
    value?: string | IObject | Array<IObject>;
  }
  export interface IOrgState {
    job_list: Array<IObject>;
    org_details: IObject;
  }
  export interface ICreateTemplate {
    template_name: string;
    org?: string;
    job_id: string;
    group_id?: string;
    pdf_data?: object;
    user?: object;
    org_details?: object;
    job_details?: object;
  }
  
  export interface ITemplateState {
    template_list: Array<ITemplate>;
    template: IObject;
    pdf_data: {
      url: string;
      canvas_array: string[];
      pageAttributes?: IObject;
      page: number;
    };
    inspectionData: IObject
  }
  
  export interface ITemplate {
    _id: string;
    template_name?: string;
    org?: number;
    job_id?: number;
    group_id?: number;
    pdf_data: IObject;
    user?: IObject;
    org_details?: IObject;
    job_details?: IObject;
    version?: number;
    parent_template?: string;
    created_at?: string;
    modified_at?: string;
    editable?: boolean;
  }
  
  export interface IEditTemplate {
    template_id: string;
    template_name?: string;
    org?: number;
    job_id?: number;
    group_id?: number;
    pdf_data?: object;
    org_details?: object;
    job_details?: object;
    user?: object;
    editable?: boolean;
  }
  
  export enum ToolbarTypes {
    TEXT = "TEXT",
    TRUE = "TRUE",
    FALSE = "FALSE",
    RADIO = "RADIO",
    CIRCLE = "CIRCLE",
    IMAGE = "IMAGE",
    SIGNATURE = "SIGNATURE",
  }
  
  export enum TabTypes {
    MEASUREMENT = "Measurement",
    FORM = "Inspection form",
  }
  
  export enum ColorPickerTypes {
    SKETCH = "SKETCH",
    TWITTER = "TWITTER",
  }
  
  export interface IPoints {
    label: String;
    reference?: IPoints;
    _id: String;
    x?: number;
    y?: number;
    z?: number;
    u?: number;
    v?: number;
    w?: number;
    created_at?: String;
    modified_at?: String;
  }
  
  export interface IMeasurements {
    _id: String;
    answer_type?: String;
    measurement_type?: String;
    points: Array<IPoints>;
    value?: number;
    label?: String;
    unit?: String;
    created_at?: String;
    modified_at?: String;
    interval_distance?: String;
  }
  export enum FileTypes {
    PDF = "PDF",
    IMAGE = "IMAGE",
    VIDEO = "VIDEO",
  }
  export interface ICreateMedia {
    url: string;
    name: string;
    template?: string;
    user: object;
    org_details: object;
    org: string;
  }
  export interface IEditorState {
    editor: IObject;
    editor_list: Array<IObject>
  }
  
  export enum GRID_AXIS {
    X="X",
    Y="Y"
  }
  
  export enum GRID_METHOD {
    AVERAGE ="AVERAGE",
    LOWEST ="LOWEST",
    MIDDLE ="MIDDLE",
    HIGHEST ="HIGHEST",
  }
  
  export enum WORKFLOW {
    GUIDED = "GUIDED",
    CUSTOM = "CUSTOM"
  }
  
  export enum GRID_COMPONENT_TYPE {
    FULL = "FULL",
    INDIVIDUAL = "INDIVIDUAL",
  }
  
  export enum GRID_RAINFALL_VALUE {
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HEAVY = 'HEAVY '
  }
  
  export  interface IHistoryLog {
    _id?: string;
    is_deleted?: boolean;
    template?: string;
    inspection?: string;
    org?: number;
    group_id?: number;
    event?: string;
    description?: string;
    job_id?: number;
    org_details?: object;
    job_details?: object;
    user?: object;
    page?: string;
  }
  export enum SWITCHING_VIEW {
    TOP = "TOP",
    BOTTOM = "BOTTOM",
    FRONT = "FRONT",
    BACK = "BACK",
    LEFT = "LEFT",
    RIGHT = "RIGHT",
  }
  
  export enum ORIENTATION {
    PORTRAIT = 'portrait',
    LANDSCAPE = "landscape"
  }
  
  export interface IGridAnalysis {
    _id: string;
    answer_type: string;
    measurement_type: string;
    points: Array<IPoints>;
    interval_distance: string;
    label: string;
    measurement_lines: string;
    unit: string;
    created_at: string;
    modified_at: string;
    startingX: string;
    endingX: string;
    startingY: string;
    endingY: string;
    component_type: string;
    method: string;
    axis: string;
    rainfall_value: string;
  }
  
  export interface IElevationAnalysis {
    _id: string;
    answer_type: string;
    measurement_type: string;
    points: Array<IPoints>;
    interval_distance: string;
    label: string;
    measurement_lines: string;
    unit: string;
    created_at: string;
    modified_at: string;
    component_type: string;
    method: string;
  }
  
  export interface IComments {
    _id: String;
    x: number;
    y: number;
    z: number;
    created_at?: string;
    modified_at?: string;
    message: string
  }
  export enum FormatTypes {
    LANDSCAPE = "landscape",
    PORTRAIT = "portrait"
  }
  
  export enum Inspection {
    ID = "id",
    UNIQUE_ID = "uniqueId",
    NORTH_SOUTH_STREET_NAME = "northSouthStreetName",
    INSPECTION_NAME = "inspectionName",
    PACKAGE = "package",
    JOB_ID = 'jobId',
    GROUP_ID = 'groupId',
    INTERSECTION_TYPE = "intersectionType",
    EAST_WEST_STREET_NAME = "eastWestStreetName",
    INSPECTOR_ID = 'inspectorId',
    INSPECTOR_ADDED_OR_UPDATED_DATE = 'inspectorAddedOrUpdatedDate',
    ACCESS_TYPE = "accessType",
    SERVES_MULTIPLE_CROSSWALKS = "servesMultipleCrosswalks",
    USER_COMMENTS = "userComments",
    SUPERVISOR_COMMENTS = "supervisorComments",
    MISCELLANEOUS_INFO1 = "miscellaneousInfo1",
    MISCELLANEOUS_INFO2 = "miscellaneousInfo2",
    MISCELLANEOUS_INFO3 = "miscellaneousInfo3",
    NOTIFICATIONS_FROM_SITE = "notificationsFromSite",
    RID = "rid",
    LATITUDE = "latitude",
    LONGITUDE = "longitude",
    MARKERS_COUNT = 'markersCount',
    M1X = 'm1x',
    M1Y = 'm1y',
    M1Z = 'm1z',
    M2X = 'm2x',
    M2Y = 'm2y',
    M2Z = 'm2z',
    M3X = 'm3x',
    M3Y = 'm3y',
    M3Z = 'm3z',
    M4X = 'm4x',
    M4Y = 'm4y',
    M4Z = 'm4z',
    CROP_OFFSET = 'cropOffset',
    VERTICAL_THRESHOLD_LOW = 'verticalThresholdLow',
    VERTICAL_THRESHOLD_HIGH = 'verticalThresholdHigh',
    HORIZONTAL_THRESHOLD_LOW = 'horizontalThresholdLow',
    HORIZONTAL_THRESHOLD_HIGH = 'horizontalThresholdHigh',
    HORIZONTAL_MVP_MEASUREMENT = 'horizontalMVPMeasurement',
    VERTICAL_MVP_MEASUREMENT = 'verticalMVPMeasurement',
    ACQUISITION_DATE = 'acquisitionDate',
    STATUS = 'status',
    STATUS_PROGRESS = 'statusProgress',
    STATUS_MESSAGE = 'statusMessage',
    CREATED_AT = 'createdAt',
    CREATED_BY = 'createdBy',
    LAST_UPDATED_AT = 'lastUpdatedAt',
    LAST_UPDATED_BY = 'lastUpdatedBy',
    JENKINS_URL = 'jenkinsUrl',
    DUE_DATE = 'dueDate',
    INSPECTOR_NAME = 'inspectorName',
    INSPECTOR_EMAIL = 'inspectorEmail',
  }

  export enum Tabs{
    DASHBOARD = 'DASHBOARD',
    USERS = 'USERS',
    VENDORS = 'VENDORS',
    SUPPORT = 'SUPPORT'
  }

  export enum Settings{
    PROFILE_SETTINGS = "Profile settings",
    CHANGE_PASSWORD = "Change password",
    SUPPORT = "Support",
    LOGOUT = "Logout"
  }