import React, { useEffect, Fragment } from 'react';
import { useSetState } from 'utils/functions.utils';
import { useSelector } from 'react-redux';
import { reducers } from 'interfaces/common.interface';
import connectSocket from 'utils/socket.utils';
import { HeaderComponent } from 'utils/imports.utils';
import NavBar from 'components/nav_bar/nav_bar.component';

let socket: any;

export default function Main(props: any) {
  const [state, setState] = useSetState({ signout: false, loading: false });
  const user = useSelector((store: reducers) => store.user);

  useEffect(() => {
    // socket = connectSocket();
  }, []);

  const showSuccess = (text: string) => {
    //show snack success
  };

  const throwError = (text: string) => {
    //show snack error
  };

  const setMainLoading = (loading: boolean) => {
    setState({ loading: loading });
  };

  const renderChildren = () => {
    return React.Children.map(props.children, (child: any) => {
      if (child) {
        return React.cloneElement(child, {
          user,
          showSuccess,
          throwError,
          socket,
          setMainLoading,
        });
      }
    });
  };

  if (state.signout) window.location.href = '/';
  if (state.loading) return <div>Loading</div>;
  // const token = localStorage.getItem('token');
  // if (!token) window.location.href = '/auth';
  return (
    <div className={props.className}>
      <div
        style={{
          display: 'flex',
          minHeight: '100vh',
          flexDirection: 'column',
          position: 'relative',
          background: '#F8FAFC',
        }}>
          {props.className === "admin" ? null : <HeaderComponent/>}
          <div
            className="main_body"
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {renderChildren()}
          </div>
      </div>
    </div>
  );
}
