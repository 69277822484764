import Main from 'common_components/hoc/main.hoc';
import { useSetState } from 'utils/functions.utils';
import {
  Assets,
  FontFamily,
  Input,
  Models,
  PrimaryButton,
} from 'utils/imports.utils';
import { ButtonSize, ButtonTypes } from 'utils/interface.utils';
import './reset_password.screen.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const ResetPassword = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {id} = useParams()
  const [state, setState] = useSetState({
    passwordNew: '',
    passwordConfirm: '',
    passwordNewInputType: true,
    passwordConfirmInputType: true,
  });

  const handlePasswordReset = async (e: any) => {
    if (state.passwordNew !== state.passwordConfirm) {
      console.log('Password do not match');
      setState({ error: 'Passwords do not match.' });
      return;
    }
    if (!id) {
      setState({ error: 'Invalid or missing reset password hash.' });
      return;
    }
    setState({ activity: true });

    try {
      const response = await Models.auth.resetPassword({
        password: state.passwordNew,
        reset_password_hash: id,
      });
      if (response) {
        console.log('Password reset successful:', response);
        navigate('/admin/password_changed');
      }
    } catch (error) {
      console.error('Password reset error:', error);
      setState({ error: 'Password reset failed. Please try again.' });
    } finally {
      setState({ activity: false });
    }
  };
  return (
    <Main className="admin">
      <div className="reset_password">
        <div className="reset_password_container">
          <div className="reset_password_header">
            <div className="reset_password_heading">Reset password</div>
          </div>
          <div className="reset_password_details_container">
            <div className="reset_password_input_container reset_password_input_container_new">
              <Input
                onChange={(e) => setState({ passwordNew: e.target.value })}
                value={state.passwordNew}
                label="New password"
                placeholder="enter your email id here"
                type={state.passwordNewInputType ? 'password' : 'text'}
                icon={
                  state.passwordNewInputType ? Assets.eye_slash : Assets.eye
                }
                iconPosition="end"
                iconOnPress={() =>
                  setState({
                    passwordNewInputType: !state.passwordNewInputType,
                  })
                }
              />
            </div>
            <div className="reset_password_input_container reset_password_input_container_old">
              <Input
                onChange={(e) => setState({ passwordConfirm: e.target.value })}
                value={state.passwordConfirm}
                label="Confirm password"
                placeholder="enter your email id here"
                type={state.passwordConfirmInputType ? 'password' : 'text'}
                icon={
                  state.passwordConfirmInputType ? Assets.eye_slash : Assets.eye
                }
                iconPosition={'end'}
                iconOnPress={() =>
                  setState({
                    passwordConfirmInputType: !state.passwordConfirmInputType,
                  })
                }
              />
            </div>
          </div>
          <div className="reset_password_button_container">
            <PrimaryButton
              onClick={(e) => handlePasswordReset(e)}
              type={ButtonTypes.PRIMARY}
              size={ButtonSize.SMALL}
              text="Reset"
              fontFamily={FontFamily.semiBold}
            />
          </div>
        </div>
      </div>
    </Main>
  );
};

export default ResetPassword;
