import React, { useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { useSetState } from "utils/functions.utils";
import "./dropdown.scss";
import Assets from "../../../imports/assets.import";
import Select, { components,MenuListProps, OptionProps } from "react-select";
import { IDropdownData, IObject } from "utils/interface.utils";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";
import _ from "lodash";
import { Models } from "utils/imports.utils";

interface IDropdownProps extends StateManagerProps {
  color?:string,
  data: Array<IDropdownData>;
  className?: string;
  value: any;
  clear?: boolean;
  containerClassName?: string;
  error?: any;
  icon?: string;
  label?: string;
  id?: string;
}

export type Ref = any;
const Dropdown = forwardRef<Ref, IDropdownProps>((props, ref) => {
  // State
  const [state, setState] = useSetState({
    isOpen: false,
    active: "",
    selectedOption:props.value,
  });

  const dropdownRef: any = useRef();

  //Logic
  const customStyles: any = {
    multiValueRemove: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "transparent",
        color: "white",
      },
    }),
  };

  useImperativeHandle(ref, () => ({
    focus(){
      dropdownRef.current.focus()
    }
  }));

  const DropdownIndicator = dropdownProps => {
    return (
      <div>
        <img src={getDropDownIndicatorIcon()} height={16} width={16} />
      </div>
    );
  };

  const ClearIndicator = props => {
    return (
      <div style={{ paddingRight: "10px", paddingTop: "5px" }} onClick={props.clearValue}>
        <img src={Assets.close_black} height={20} width={20} />
      </div>
    );
  };

  const gettingDropdownData = () => {
    return props.data?.map((item,index) => {
      if (item.hasOwnProperty('label') && item.hasOwnProperty('value')) {
        return item;
      } else if (item.hasOwnProperty('label') && !item.hasOwnProperty('value')) {
        return {
            //@ts-ignore
            value: item.label,
            ...item
        };
      } else {
        return item;
      }
    });
  };

  // Color mapping for statuses
  const colorStyles = {
    "Under review": "#E64D00",
    "Resolved": "#14532D",
    "Closed": "#6B7280",
  };

  const colorStylesOption = {
    "Under review": "#FFEEE5",
    "Resolved": "#F0FDF4",
    "Closed": "#F8FAFC",
  }

  // Custom Option component with circular dot
  const CustomOption = (props: OptionProps<any>) => {
    const { children, innerRef, innerProps, isDisabled, isSelected, isFocused, data } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={"drop_down_data_container"}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent:"flex-start",
          backgroundColor: isDisabled
            ? undefined
            : isSelected
            ?  colorStylesOption[data.value]
            : isFocused
            ? '#F2F9FF'
            : "#fff",
          color: isDisabled ? '#ccc' : '#000',
          cursor: isDisabled ? 'not-allowed' : 'default',
        }}
      >
        <span
          style={{
            height: 6,
            width: 6,
            borderRadius: '50%',
            backgroundColor:colorStyles[data.value],
            display: 'inline-block',
            marginRight: 10,
          }}
        />
        <span className={"drop_down_data_item"}>{children}</span>
      </div>
    );
  };

  const getClassName = () => {
    switch (state.selectedOption.value){
      case "Under review":
        return "drop_down_under_review";
      case "Resolved":
        return "drop_down_resolved";
      case "Closed":
        return "drop_down_closed";
      default:
        return "";
    }
  }

  const getDropDownIndicatorIcon = () => {
    switch (state.selectedOption.value){
      case "Under review":
        return Assets.caret_down_red;
      case "Resolved":
        return Assets.caret_down_green;
      case "Closed":
        return Assets.caret_down_gray;
      default:
        return "";
    }
  }
  const UpdateSupportStatus = async (body) => {
    try {
        const response:any = await Models.auth.updateSupportStatus(body)
        console.log("Tickets",response);
       
    } catch (err) {
        console.log('Failed to update support Status', err);
    }
};
  const handleChange = (option:any) => {
    setState({
      selectedOption:option,
    })
   UpdateSupportStatus({status:option.value, ticketId:props.id})
  }
  
  return (
    <div className={`dropdown_container ${props.containerClassName || ""}`}>
      {props.clear && (
        <div
          className={`${props.isMulti && props.value.length > 0 && "dropdown_clear_multi"} dropdown_clear_text`}
          onClick={() => dropdownRef?.current?.clearValue()}
        >
          Clear
        </div>
      )}
      {props.label && <div className="dropdown_label_wrapper">{props.label}</div>}
      {/* <div className={"dropdown_wrapper"}> */}
        {/* {props.icon && (
          <div className="icon-wrapper">
            <img src={props.icon} alt="image" className="dropdown_icon"/>
          </div>
        )} */}
        <Select
          value={state.selectedOption}
          onChange={handleChange}
          ref={dropdownRef}
          // menuPosition="fixed"
          maxMenuHeight={200}
          styles={customStyles}
          options={gettingDropdownData()}
          placeholder={props.placeholder}
          components={{
            DropdownIndicator,
            ClearIndicator,
            IndicatorSeparator: () => {
              return null;
            },
            Option:CustomOption,
          }}
          classNames={{
            // control: (state) => (props.className ? props.className : state.isFocused ? "dropdown_control_active" : "dropdown_control"),
            control: state => (state.isFocused ? `${getClassName()}_active` : getClassName() || ""),
            indicatorSeparator: state => (state.isFocused ? "dropdown_indicator_separator" : "dropdown_indicator_separator"),
            multiValue: state => (state.isFocused ? "dropdown_multi_value" : "dropdown_multi_value"),
            multiValueLabel: state => (state.isFocused ? "dropdown_multi_value_label" : "dropdown_multi_value_label"),
            singleValue: state => (
              getClassName() === "drop_down_under_review" ? "drop_down_under_review_single" : 
              getClassName() === "drop_down_resolved" ? "drop_down_resolved_single" :
              getClassName() === "drop_down_closed" ?  "drop_down_closed_single" : ""
            ),
            valueContainer: state => (state.isDisabled ? "dropdown_input_value" : "dropdown_input_value"),
            option: state => (state.isSelected ? "menu_list_item_selected" : state.isFocused ? "menu_list_item_focused" : "menu_list_item"),
            input: state => "select_box_input",
          }}
        />
      {/* </div> */}
      {props.error && props.error.length > 0 && <div className="input_field_error">{props.error}</div>}
    </div>
  );
})

export default Dropdown;
