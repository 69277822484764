import React from "react";
import "./primary_button.ui.scss";
import { ButtonSize, ButtonTypes } from "utils/interface.utils";
import { Colors}  from "utils/theme.utils";
import Loader from "../loader/loader.ui";
import Assets from "imports/assets.import";

interface IPrimaryButton {
  text?: String;
  color?: any;
  left_icon?: any;
  className?: any;
  onClick?: any;
  backgroundColor?: string;
  disabled?: boolean;
  style?: any;
  right_icon?: any;
  activity?: boolean;
  type?: string;
  size?: string;
  fontFamily?: string;
}

const PrimaryButton = (props: IPrimaryButton) => {
  //logic
  const getButtonStyles = () => {
    if (props.size === ButtonSize.SMALL) {
      return "btn_small";
    } else if (props.size === ButtonSize.MEDIUM) {
      return "btn_medium";
    } else if (props.size === ButtonSize.LARGE) {
      return "btn_large";
    }
  };

  const getBgColor = () => {
    if (props.type === ButtonTypes.PRIMARY) {
      return { backgroundColor: Colors.primaryColor };
    } else if (props.type === ButtonTypes.SECONDARY) {
      return { backgroundColor: Colors.secondaryColor };
    } else if (props.type === ButtonTypes.OUTLINED) {
      return { backgroundColor: Colors.transparent, border: `1px solid ${Colors.buttonBorder}`, color: Colors.fontPrimary };
    } else if (props.type === ButtonTypes.TEXT) {
      return { backgroundColor: Colors.transparent, color: Colors.fontSecondary };
    } else if (props.type === ButtonTypes.DISABLED){
      return { backgroundColor: Colors.primaryColor, color:Colors.primaryWhite, opacity:0.3};
    }else {
      return { backgroundColor: props.backgroundColor };
    }
  };

  return (
    <div
      className={`${props.disabled ? `button_wrapper btn_disabled ${getButtonStyles()}` : `button_wrapper ${getButtonStyles()} `} ${
        props.className || ""
      }`}
      style={{
        ...getBgColor(),
        ...props.style,
      }}
      onClick={() => {
        if (!props.disabled) {
          props.onClick();
        }
      }}
    >
      {props.activity ? (
        <div className="primary_button_img">
          <Loader icon={props.type !== ButtonTypes.PRIMARY ? Assets.loader_1: Assets.loader_1} />
        </div>
      ) : (
        <>
          {props.left_icon && (
            <div className="primary_button_img">
              <img src={props.left_icon} alt="icon" className="primary_btn_icon" />
            </div>
          )}
          {props.text && (
            <div style={{ color: props.color, fontFamily: props.fontFamily }} className="button_text">
              {props.text}
            </div>
          )}
          {props.right_icon && (
            <div className="primary_button_right_img">
              <img src={props.right_icon} alt="icon" className="primary_btn_icon" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PrimaryButton;
