import Assets from "imports/assets.import";
import "./nav_bar.component.scss";

const NavBar = (props:any) => {
   
    return (
        <div className="nav_bar">
            <div className="nav_bar_container">
                <div className="nav_bar_logo_container">
                    <div className="nav_bar_logo_img_container">
                        <img className="nav_bar_logo_img" src={Assets.logo} alt="Quick Serve Logo"/>
                    </div>
                    <div className="nav_bar_logo_title_container">
                        <div className="nav_bar_logo_title">QuickServe</div>
                    </div>
                </div>
                <div className="nav_bar_profile_edit_container" onClick={props.onClick}>
                    <div className="nav_bar_profile_edit_img_container">
                        <img className="nav_bar_profile_edit_img" src={ props.profileData?.profile_picture ||Assets.dummy_profile} alt="Admin Profile Image"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NavBar