import React from "react";
import { FontFamily, PrimaryButton } from "utils/imports.utils";
import { ButtonSize, ButtonTypes } from "utils/interface.utils";
import "./feedback_modal.component.scss";

interface IFeedbackModal {
    closeModal:Function,
}

const FeedbackModal = (props:IFeedbackModal) => {
    return (
        <div className="feedback_modal">
            <div className="feedback_modal_container">
                <div className="feedback_modal_heading_container">
                    <div className="feedback_modal_heading">Thank you for your feedback,<br/>
                    Your complaint has been received and is being reviewed.</div>
                </div>
                <div className="feedback_modal_button_container">
                        <PrimaryButton
                            fontFamily={FontFamily.medium}
                            text="Back to home"
                            type={ButtonTypes.OUTLINED}
                            size={ButtonSize.SMALL}
                            onClick={() => props.closeModal()}
                        />
                </div>
            </div>
        </div>
    )
}
export default FeedbackModal;