import Assets from "imports/assets.import";
import { FontFamily, PrimaryButton } from "utils/imports.utils";
import { ButtonSize, ButtonTypes } from "utils/interface.utils";
import "./posted_ad_card_comonent.scss";
import AdDetailsModal from "components/ad_details_modal/ad_details_modal.component";
import { useSetState } from "utils/functions.utils";

interface IPostedAdCard {
    title:string,
    description:string,
    date:string,
    photos:any,
    price:any,
}

const PostedAdCard = (props:IPostedAdCard) => {
    const [state,setState] = useSetState({
        isAdDetailsModalOpen:false,
    })

    return (
        <>
            <div className="posted_ad_card">
                <div className="posted_ad_card_container">
                    <div className="posted_ad_card_details_container">
                        <div className="posted_ad_card_details_photos_container">
                            <img src={props.photos[0]} alt="Posted Ads Photo"/>
                            <div className="posted_ad_card_details_photos_number">
                                <div className="posted_ad_card_details_photos_number_text">1/{props.photos.length}</div>
                            </div>
                        </div>
                        <div className="posted_ad_card_details_data">
                            <div className="posted_ad_card_details_data_primary">
                                <div className="posted_ad_card_details_data_title">{props.title}</div>
                                <div className="posted_ad_card_details_data_description">{props.description}</div>
                            </div>
                            <div className="posted_ad_card_details_data_secondary">
                                <div>
                                    <div className="posted_ad_card_details_data_icon_container">
                                        <img src={Assets.calendar} alt="Calendar icon"/>
                                    </div>
                                    <div className="posted_ad_card_details_data_date">{props.date}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="posted_ad_card_button_container">
                        <PrimaryButton
                            fontFamily={FontFamily.semiBold}
                            type={ButtonTypes.PRIMARY}
                            size={ButtonSize.SMALL}
                            text="View details"
                            onClick={() => setState({isAdDetailsModalOpen:true})}
                        />
                    </div>
                </div>
            </div>
                       {/* AD_DETAILS MODAL */}
                {
                        state.isAdDetailsModalOpen && 
                        <AdDetailsModal
                            title={props.title}
                            description={props.description}
                            date={props.date}
                            price={props.price}
                            photos={props.photos}
                            closeModal={() => setState({isAdDetailsModalOpen:false})}
                        />
                }
        </>
    )
}

export default PostedAdCard