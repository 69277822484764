import Assets from "imports/assets.import";
import "./ad_details_modal.component.scss"

interface IAdDetailsModal {
    date:any,
    photos?:Array<string>,
    title:string,
    description:string,
    price:string | number,
    closeModal:Function,
}

const AdDetailsModal = (props:IAdDetailsModal) => {
    const handleAdDetailsModalClose = (e:React.MouseEvent<HTMLDivElement,MouseEvent>) => {
        if((e.target as HTMLDivElement).className.includes("ad_details")){
            props.closeModal()
        }
    }

    return (
        <div className="ad_details" onClick={handleAdDetailsModalClose}>
            <div className="ad_details_container">
                <div className="ad_details_header">
                    <div>
                        <div className="ad_details_header_title">Advertisement details</div>
                    </div>
                    <div className="ad_details_header_close_img_container" onClick={() => props.closeModal()}>
                        <img src={Assets.close_black} alt="Close Ad Details Modal Icon"/>
                    </div>
                </div>
                <div className="ad_details_data">
                    <div className="ad_details_data_container">
                        <div className="ad_details_data_label">Posted date</div>
                        <div className="ad_details_data_value">{props.date}</div>
                    </div>
                    <div className="ad_details_data_container ad_details_data_container_photos">
                        <div className="ad_details_data_label">Photos</div>
                        <div className="ad_details_data_photos_list">
                            {props.photos && props.photos.length > 0 ? (
                                    props.photos.map((item, index) => (
                                        <div className="ad_details_data_photo">
                                            <img key={index} src={item} alt={`Photo ${index + 1}`} />
                                        </div>
                                    ))
                                ) : (
                                    <div className="ad_details_data_no_photos">No photos available</div>
                            )}
                        </div>
                    </div>
                    <div className="ad_details_data_container">
                        <div className="ad_details_data_label">Service title</div>
                        <div className="ad_details_data_value">{props.title}</div>
                    </div>
                    <div className="ad_details_data_container">
                        <div className="ad_details_data_label">Service description</div>
                        <div className="ad_details_data_value">{props.description}</div>
                    </div>
                    <div className="ad_details_data_container">
                        <div className="ad_details_data_label">Price</div>
                        <div className="ad_details_data_value">₹ {props.price}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdDetailsModal;