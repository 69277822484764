import React, { useEffect, useRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { useSetState } from "utils/functions.utils";
import { Assets, Functions, Models } from "utils/imports.utils";
import './loader.ui.scss';

const Loader = (props) => {
  // Reference

  // Redux

  // State

  //Hooks

  // Network req

  //Logic

  return (
    <div className="loader_wrapper">
      <img src={props.icon || Assets.logo} width={props.width||"30px"} height={props.height||"30px"} />
    </div>
  );
};

export default Loader;
