import React, { useEffect } from 'react'
import { useSetState } from 'utils/functions.utils';
import { Models } from 'utils/imports.utils';

const TotalAdvertisement = () => {
  const [state, setState] = useSetState({
    getTotalCountAdvertisement: null,
  });
  const totalCountAdvertisement = async () => {
    try {
      const response: any = await Models.auth.totalCountAdvertisement();
      setState({ getTotalCountAdvertisement: response.data });
    } catch (err) {
      console.log('Failed to fetch total length of Advertisement', err);
    }
  };
  useEffect(() => {
    totalCountAdvertisement();
  }, []);
  return (
   <>
    <div className="admin_dashboard_summary_item_label">Total advertisement</div>
    <div className="admin_dashboard_summary_item_value">{state?.getTotalCountAdvertisement}</div>
   </>
  )
}

export default TotalAdvertisement