import { GET_USER } from "utils/types.utils";
import { storeAction } from 'interfaces/common.interface'

const localUser:any = localStorage.getItem("user")
// console.log(localUser);
let user;
if (localUser) {
  user=JSON.parse(localUser)
}
const initialState = {
  user: user ?? undefined
};

const UserReducer = (state = initialState, action: storeAction) => {
  switch (action.type) {
    case GET_USER:
      let newState = { ...state }
      newState = { ...newState, ...action.payload }
      return newState
    default:
      return state;
  }
}

export default UserReducer
