import React, { useEffect, useMemo, useRef } from 'react';
import Assets from 'imports/assets.import';
import {FontFamily} from 'utils/theme.utils';
import './request.screen.scss';
import { Input, LocationModal, Models } from 'utils/imports.utils';
import { getAddressFromCoords, useSetState } from 'utils/functions.utils';
import { PrimaryButton } from 'utils/imports.utils';
import { Slider } from 'utils/imports.utils';
import { ButtonTypes } from 'utils/interface.utils';
import { GOOGLE_MAPS_API_KEY } from 'utils/constant.utils';
import { useJsApiLoader,Libraries } from '@react-google-maps/api';
import { useNavigate } from 'react-router-dom';
export default function Request() {
    const [state, setState] = useSetState({
        mobileNumber:"",
        mobileNumberError:"",
        priceRangeError:"",
        location:{
            lat:null,
            lng:null,
        },
        locationName:"",
        minPrice:1000,
        maxPrice:10000,
        isDisabled:true,
        min:1000,
        max:10000,
        step:100,
        sliderValue:[1000,10000],
        defaultValue:[1000,10000],
        isLocationInputDisabled:true,
        profilePhotoSrc:Assets.profile_dummy,
        activity:false
    });

    const modelRef: any = useRef(null);
    const navigate = useNavigate()

    const isValidIndianMobileNumber = (number) => {
        const regex = /^[6-9]\d{9}$/;
        return regex.test(number);
    };

    const handleUseCurrentLocation = () => {
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(
                async (position) => {
                    const {latitude,longitude} = position.coords;
                    console.log(latitude,longitude)
                    const locationName:any = await getAddressFromCoords(latitude,longitude)
                    setState({
                        locationName:locationName.addressShort,
                        location:{
                            lat:latitude,
                            lng:longitude,
                        },
                        isLocationInputDisabled:false,
                    })
                },
                (error) => {
                    if (error.code === error.PERMISSION_DENIED) {
                        console.error("User denied the request for Geolocation.");
                    } else if (error.code === error.POSITION_UNAVAILABLE) {
                        console.error("Location information is unavailable.");
                    } else if (error.code === error.TIMEOUT) {
                        console.error("The request to get user location timed out.");
                    } else {
                        console.error("An unknown error occurred.");
                    }
                }
            )
        }else {
            console.error("Geolocation is not supported by this browser.");
        }
    }


    const libraries: Libraries = useMemo(() => ["places"], []);
    const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: GOOGLE_MAPS_API_KEY,
      libraries: libraries,
      region: "in",
      id: "google-map-script",
    });

    const handleSliderInput = (value: any) => {
        setState({ 
            sliderValue: value,
            minPrice:value[0],
            maxPrice:value[1]
        });
    };

    const handleMinPrice = (e) => {
        setState(
            {
                minPrice:e.target.value,
                sliderValue:[e.target.value,state.sliderValue[1]]
            }
        )
    }

    const handleMaxPrice = (e) => {
        setState(
            {
                maxPrice:e.target.value,
                sliderValue:[state.sliderValue[0],e.target.value]
            }
        )
    }

    const handleSendRequest = async () => {
        try{
            setState({activity:true})
            if(!isValidIndianMobileNumber(Number(state.mobileNumber))){
                setState({
                    mobileNumberError:"Invalid Mobile Number",
                    priceRangeError:"",
                })
            }else if(state.minPrice > state.maxPrice){
                setState({ 
                    priceRangeError: "Minimum price cannot be greater than maximum price",
                    mobileNumberError:"",
                 });
            }else{
                const body = {
                    phone_number:state.mobileNumber,
                    location:`${state.location.lng},${state.location.lat}`,
                    min_price:state.minPrice,
                    max_price:state.maxPrice
                }
                console.log(body)
                const response = await Models.serviceRequest.createServiceRequest(body);
                console.log("Response:",response)
                setState({mobileNumberError:"",activity:false})
                navigate("/success")
            }
        }catch(error){
            setState({activity:false})
            console.log("Error createServiceRequest:",error)
        }
        setState({activity:false});
    }

    useEffect(() => {
        const { mobileNumber, location, minPrice, maxPrice } = state;
        if (mobileNumber && location.lat && location.lng && minPrice && maxPrice) {
            setState({ isDisabled: false });
        } else {
            setState({ isDisabled: true });
        }
    }, [state.mobileNumber, state.location, state.minPrice, state.maxPrice]);

    return (
            <div className="request">
                <div className="request_container">
                    <div className="request_heading">
                        Welcome to <div className="request_heading_bold">QuickServe.</div>
                    </div>
                    <div className="request_heading_sec">
                        Your Convenient Service Connection.
                    </div>
                    <div className="request_details_container">
                        <div className="request_number_input_container">
                            <Input onChange={(e) => {setState({mobileNumber:e.target.value})}} value={state.mobileNumber} label={"Mobile Number"} placeholder={"81444 81683"} type={"number"} error={state.mobileNumberError} icon={Assets.dialing_code} iconPosition="start"/>
                        </div>
                        <div className="request_enter_number_text">Enter your mobile number to receive the agent's details.</div>

                        <div className="request_location_input_container">
                            <Input
                                onChange={(e) => {setState({location:e.target.value})}}
                                placeholder='Koyambedu, Chennai.'
                                value={state.locationName}
                                type="text"
                                icon={Assets.map_pin}
                                iconPosition="start"
                                disabled={state.isLocationInputDisabled}
                            />
                        </div>
                        <div className="request_location_container">
                            <div className="request_use_current_location_container" onClick={handleUseCurrentLocation}>
                                <div className="request_use_current_location_img_container">
                                    <img src={Assets.gps_fix} alt="GPS fix icon"/>
                                </div>
                                <div>
                                    <div className="request_use_current_location_desc">Use current location</div>
                                </div>
                            </div>
                            <div className="request_select_location_container" onClick={() => {
                                    modelRef.current.openModal();
                            }}>
                                <div className="request_select_location_img_container">
                                    <img src={Assets.map_pin_area} alt="Select location using map icon"/>
                                </div>
                            </div>
                        </div>
                        <div className="request_range_container">
                            <div className="request_range_label">Price Range</div>
                            <div className="request_range_pick_container">
                                <div className="request_range_slider_container">
                                    <Slider onInput={handleSliderInput} value={state.sliderValue} defaultValue={state.defaultValue} min={state.min} max={state.max} step={state.step}/>
                                </div>
                                <div className="request_range_input_flex">
                                    <div className="request_range_input_container">
                                        <div className="request_range_currency_symbol">₹</div>
                                        <Input onChange={handleMinPrice} value={state.minPrice} type={"number"}/>
                                    </div>
                                    <div className="request_range_to_text">to</div>
                                    <div className="request_range_input_container">
                                        <div className="request_range_currency_symbol">₹</div>
                                        <Input onChange={handleMaxPrice} value={state.maxPrice} type={"number"}/>
                                    </div>
                                </div>
                            </div>
                            <div className="request_range_error_message">{state.priceRangeError}</div>
                        </div>
                        <div className="request_info_container">
                            <div className="request_info_img_container">
                                <img src={Assets.info} className="request_info_img"></img>
                            </div>
                            <div className="request_info_text">
                                Your details are confidential and won't be shared with our vendors.
                            </div>
                        </div>
                    </div>
                    <div className="request_send_req_container">
                        <PrimaryButton 
                            onClick={(e: any) => {handleSendRequest()}} 
                            disabled={state.isDisabled}
                            fontFamily={FontFamily.semiBold} 
                            text={"Send Request"} 
                            type={state.isDisabled ? ButtonTypes.DISABLED : ButtonTypes.PRIMARY}
                            activity={state.activity}
                        />
                    </div>
                    {
                        isLoaded &&
                        <LocationModal
                            ref={modelRef}
                            handleCallBack={(locationCoords:any,address:any) => {
                                console.log("handleCallBack:",locationCoords,address)
                                setState({
                                    location:{
                                        lat:locationCoords.lat,
                                        lng:locationCoords.lng
                                    },
                                    locationName:address,
                                    isLocationInputDisabled:false,
                                })
                            }}
                        />
                    }
                </div>
            </div>
    )
}