import React from 'react';
import Assets from 'imports/assets.import';
import { Colors, FontFamily } from 'utils/theme.utils';
import './success.screen.scss'
import { PrimaryButton } from 'utils/imports.utils';
import { ButtonSize, ButtonTypes } from 'utils/interface.utils';
import { useNavigate } from 'react-router-dom';

export default function Success(){
    const navigate = useNavigate()
    return(
        <div className="success">
            <div className="success_container">
                <div className="success_img_container">
                    <img src={Assets.success_illustration} className="success_img"/>
                </div>
                <div className="success_desc">
                    Your request has been successfully submitted.
                    Agent details will be shared to you soon
                </div>
                <div className="success_button_container">
                    <PrimaryButton onClick={() => navigate("/")} text={"Search another service"} type={ButtonTypes.OUTLINED} size={ButtonSize.MEDIUM} fontFamily={FontFamily.medium}/>
                </div>
            </div>
        </div>
    )
}