import React, { useEffect } from "react";
import { useSetState, validatePhoneNumber } from "utils/functions.utils";
import { FontFamily, Models, PrimaryButton } from "utils/imports.utils";
import { Input } from "utils/imports.utils";
import "./support.screen.scss";
import { ButtonTypes } from "utils/interface.utils";
import { useNavigate } from "react-router-dom";
import FeedbackModal from "components/feedback_modal/feedback_modal.component"

export default function Request() {
    const [state,setState] = useSetState({
        email:"",
        phone:"",
        description:"",
        isDisabled:true,
        isFeedbackModalOpen:false,
        activity:false,
    })
    const navigate = useNavigate();

    const handleSubmit = async () => {
        try{
            const {email,phone,description} = state;
            setState({activity:true})
            const body = {
                user_email:email,
                vendor_phone_number:phone,
                issue:description
            }
            const response:any = await Models.supportRequest.createSupportRequest(body);
            console.log("response:",response);
            if(response.status === "success"){
                setState({
                    email:"",
                    phone:"",
                    description:"",
                    isFeedbackModalOpen:true,
                    activity:false,
                })
            }
        }catch(error){
            setState({activity:false})
        }
    }

    useEffect(() => {
        const {email,phone,description} = state;
        if(email && phone && description){
            if(validatePhoneNumber(phone)){
                setState({isDisabled:false})
            }
        }

        return () => setState({isDisabled:true})
    },[state.email,state.phone,state.description])

    return (
        <div className="support">
            <div className="support_container">
                <div className="support_header">
                    <div className="support_heading">Support Request Form</div> 
                    <div className="support_desc">Provide your details and issue description. Our support team will get back to you as soon as possible to assist with your request.</div>
                </div>
                <div className="support_details_container">
                    <div className="support_input_container">
                        <Input
                            onChange={(e) => {setState({email:e.target.value})}}
                            value={state.email}
                            label="Enter your mail id"
                            placeholder="Mail id"
                            type="email"
                        />
                    </div>
                    <div className="support_input_container">
                        <Input
                            onChange={(e) => {setState({phone:e.target.value})}}
                            value={state.phone}
                            label="Vendor phone no"
                            placeholder="Phone no"
                            type="number"
                        />
                    </div>
                    <div className="support_input_desc_container">
                        <Input
                            onChange={(e:any) => {
                                setState({description:e.target.value})
                            }}
                            value={state.description}
                            label="Description of the issue"
                            placeholder="Enter your issues details"
                            type="text"
                            isTextArea={true}
                        />
                    </div>
                </div>
                <div className="support_submit_btn_container">
                    <PrimaryButton
                        text={"Submit"}
                        fontFamily={FontFamily.semiBold}
                        type={state.isDisabled ? ButtonTypes.DISABLED : ButtonTypes.PRIMARY}
                        disabled={state.isDisabled}
                        onClick={handleSubmit}
                        activity={state.activity}
                    />
                </div>
                {
                    state.isFeedbackModalOpen && (
                        <FeedbackModal
                            closeModal={() => {
                                setState({isFeedbackModalOpen:false})
                                navigate("/")
                            }}
                        />
                    )
                }
            </div>
        </div>   
    )
}