import React, { useEffect } from 'react';
import { useSetState } from 'utils/functions.utils';
import { Models } from 'utils/imports.utils';

const TotalSupport = () => {
  const [state, setState] = useSetState({
    getTotalCountSupport: null,
  });
  const totalCountSupport = async (body) => {
    try {
      const response: any = await Models.auth.totalCountSupport({});
      console.log(response.data);
      setState({ getTotalCountSupport: response.data });
    } catch (err) {
      console.log('Failed to fetch total count of support', err);
    }
  };
  useEffect(() => {
    totalCountSupport({});
  }, []);
  return (
    <>
      <div className="admin_dashboard_queries_items_container">
        <div className="admin_dashboard_queries_item">
          <div className="admin_dashboard_queries_item_label">
            Total queries
          </div>
          <div className="admin_dashboard_queries_item_value">{state?.getTotalCountSupport?.totalCountSupport}</div>
        </div>
        <div className="admin_dashboard_queries_item">
          <div className="admin_dashboard_queries_item_label">
            Total under review
          </div>
          <div className="admin_dashboard_queries_item_value">{state?.getTotalCountSupport?.under_review}</div>
        </div>
        <div className="admin_dashboard_queries_item">
          <div className="admin_dashboard_queries_item_label">
            Total resolved
          </div>
          <div className="admin_dashboard_queries_item_value">{state?.getTotalCountSupport?.resolved}</div>
        </div>
      </div>
    </>
  );
};

export default TotalSupport;
