import React, { Suspense } from 'react';
import './App.scss';
import Test from 'screens/test/test.screen';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store from 'store/store';
import { Provider } from 'react-redux';
import Request from 'screens/request/request.screen';
import Success from 'screens/success/success.screen';
import Vendor from 'screens/vendor/vendor.screen';
import Main from "common_components/hoc/main.hoc";
import Support from "screens/support/support.screen";
import LogIn from 'screens/admin/login/login.screen';
import PasswordRecovery from 'screens/admin/password_recovery/password_recovery.screen';
import ResetLinkSend from 'screens/admin/reset_link_send/reset_link_send.screen';
import ResetPassword from 'screens/admin/reset_password/reset_password.screen';
import PasswordChanged from 'screens/admin/password_changed/password_changed.screen';
import Admin from 'screens/admin/admin.screen';
const token = localStorage.getItem("token");
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<div />}>
          <Routes>
            <Route path="/" element={<Main><Request/></Main>}></Route>
            <Route path="/success" element={<Main><Success/></Main>}></Route>
            <Route path="/support" element={<Main><Support/></Main>}></Route>
            <Route path="/vendor" element={<Vendor/>}></Route>
            <Route path="/admin" element={<Admin/>}></Route>
            <Route path="/admin/login" element={<LogIn/>}></Route>
            <Route path="/admin/password_recovery" element={<PasswordRecovery/>}></Route>
            <Route path="/admin/reset_link_send" element={<ResetLinkSend/>}></Route>
            <Route path="/admin/reset_password/:id" element={<ResetPassword/>}></Route>
            <Route path="/admin/password_changed" element={<PasswordChanged/>}></Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
