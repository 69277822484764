import React, { useEffect } from 'react'
import { useSetState } from 'utils/functions.utils';
import { Models } from 'utils/imports.utils';

const TotalService = () => {
  const [state, setState] = useSetState({
    getTotalCountServices: null,
  });
  const totalCountServices = async () => {
    try {
      const response: any = await Models.auth.totalCountServices();
      setState({ getTotalCountServices: response.data });
    } catch (err) {
      console.log('Failed to fetch total count of service requests', err);
    }
  };
  useEffect(() => {
    totalCountServices();
  }, []);
  return (
    <>
     <div className="admin_dashboard_summary_item_label">Total request</div>
     <div className="admin_dashboard_summary_item_value">{state?.getTotalCountServices}</div>
    </>
  )
}

export default TotalService