import axios from "axios"
import { Functions } from "./imports.utils"
import { GOOGLE_MAPS_API_KEY } from "./constant.utils"
// import AsyncStorage from "@react-native-community/async-storage"

let token = localStorage.getItem("token")

export const instance = () => {
  const data = axios.create({
  baseURL: Functions.getBaseURL()+"/api/v1/",
    headers: {
        "authorization" :token || ""
  }
})
return data
}


 export const fetchLocations = async (query) => {
  const url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${query}&types=(regions)&key=${GOOGLE_MAPS_API_KEY}`;
  try {
    const response = await axios.get(url);
    const data = response.data.predictions.map(prediction => ({
      label: prediction.description,
      value: prediction.place_id,
    }));
    return data;
  } catch (error) {
    console.error("Error fetching locations: ", error);
    return [];
  }
};


// export const instance = () => {
//   const data = axios.create({
//     baseURL: Functions.getBaseURL()+"/api/v1/",
//   })
//   data.interceptors.request.use(async function (config) {
//       const accessToken = await AsyncStorage.getItem("token");
//       config.headers['authorization'] = accessToken ? accessToken : '';
//       return config;
//   });
//   return data
// }
