import React, { useEffect } from 'react';
import { useSetState } from 'utils/functions.utils';
import { Models } from 'utils/imports.utils';

const TotalVendors = () => {
  const [state, setState] = useSetState({
    getTotalCountVendors: null,
  });
  const totalCountVendors = async () => {
    try {
      const response: any = await Models.auth.totalCountVendors();
      setState({ getTotalCountVendors: response.data.vendors });
    } catch (err) {
      console.log('Failed to fetch total length of vendors', err);
    }
  };
  useEffect(() => {
  let token = localStorage.getItem("token")
    if(token){
      totalCountVendors();
    }
  }, []);
  return (
    <>
      <div className="admin_dashboard_summary_item_label">Total vendor</div>
      <div className="admin_dashboard_summary_item_value">
        {state?.getTotalCountVendors}
      </div>
    </>
  );
};

export default TotalVendors;
