import Main from "common_components/hoc/main.hoc";
import React, { useEffect } from "react";
import "./login.screen.scss";
import { FontFamily, Input, Models, PrimaryButton } from "utils/imports.utils";
import { useSetState } from "utils/functions.utils";
import { ButtonSize, ButtonTypes } from "utils/interface.utils";
import { useNavigate } from "react-router-dom";
import axios from "axios";



const LogIn = (props:any) => {
    const navigate = useNavigate();

    const [state,setState] = useSetState({
        email:"",
        emailError:"",
        password:"",
        passWordError:"",
        activity:false,
    })

    const handleLogin = async (e:any) => {
        setState({ activity: true });

        try {
            const user = await Models.auth.login({ email: state.email,
                password: state.password,})
            if (user) {
                console.log("Login successful:", user);
                navigate("/admin"); 
            } else {
                // setState({ emailError: response.data.message || "Invalid credentials", passWordError: "" });
            }
        } catch (error) {
            console.error("Login error:", error);
            setState({ emailError: "Login failed. Please try again.", passWordError: "" });
        } finally {
            setState({ activity: false });
        }
    }

    return (
        <Main className="admin login">
                <div className="login_container">
                    <div className="login_header">
                        <div className="login_heading">
                        Login
                        </div>
                    </div>
                    <div className="login_details_container">
                        <div className="login_input_container_email">
                            <Input
                                onChange={(e) => setState({email:e.target.value})}
                                value={state.email}
                                label="Email id"
                                placeholder="enter your email id here"
                                type="email"
                                error={state.emailError}
                            />
                        </div>
                        <div className="login_input_container_password">
                            <Input
                                onChange={(e) => setState({password:e.target.value})}
                                value={state.password}
                                label="Password"
                                placeholder="enter your password here"
                                type="password"
                                error={state.passWordError}
                            />
                        </div>
                        <div className="login_forgot_password_button_container">
                            <div onClick={() => navigate("/admin/password_recovery")} className="login_forgot_password_button">Forgot your password?</div>
                        </div>
                    </div>
                    <div>
                        <PrimaryButton
                            onClick={(e) => handleLogin(e)}
                            fontFamily={FontFamily.semiBold}
                            text="Login"
                            type={ButtonTypes.PRIMARY}
                            size={ButtonSize.SMALL}
                            activity={state.activity}
                        />
                    </div>
                </div>
        </Main>
    )
}

export default LogIn;